import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import {
	LazyLoadImage,
	LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const WhatisNarm = () => (
	<Layout>
		<SEO
			title="What is NARM?"
			description="The NeuroAffective Relational Model™ (NARM) is an advanced clinical training for mental health professionals
							who work with Complex Trauma."
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
						About Us
					</h5>
					<h1>What is NARM?</h1>
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div className="intro mb-4 ">
					<div className="row">
						<div className="col-md-9 mx-auto text-center">
							<h2 className="text-primary">
								<span className="text-orange">Connection:</span>Our Deepest
								Desire & Greatest Fear
							</h2>
							<p className="text-muted">
								{" "}
								<strong />{" "}
							</p>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
		<section id="testimonials" className="component pt-0 mb-2">
			<div className="testimonials-inner-container bg-primary py-5">
				<div className="container px-5 px-md-3">
					<div className="intro mb-5">
						<h2 className="text-white">
							The NeuroAffective Relational Model™ (NARM)
						</h2>
						<p className="text-white">
							is an advanced clinical training for mental health professionals
							who work with Complex Trauma.
						</p>
					</div>
					<div class="row justify-content-between align-items-center">
						<div class="col-md-10 order-2 order-md-1">
							<p className="text-white testimonial-content">
								{" "}
								<strong className="text-white">
									The NeuroAffective Relational Model(NARM)
								</strong>{" "}
								NARM is a cutting-edge model for addressing attachment,
								relational and developmental trauma, by working with the
								attachment patterns that cause life-long psychobiological
								symptoms and interpersonal difficulties. These early,
								unconscious patterns of disconnection deeply affect our
								identity, emotions, physiology, behavior and relationships.
								Learning how to work simultaneously with these diverse elements
								is a radical shift that has profound clinical implications for
								healing Complex Trauma. As such, NARM is positioned to become an
								invaluable treatment option for the Trauma-Informed Care
								movement as we gain greater understanding of the nature of
								adverse childhood experience (ACEs). This
								developmentally-oriented, neuroscientifically-informed model, as
								outlined in Dr. Laurence Heller’s book Healing Developmental
								Trauma, emerged out of earlier psychotherapeutic orientations
								including Psychodynamic Psychotherapy, Attachment Theory,
								Cognitive Therapy, Gestalt Therapy, and Somatic Experiencing®,
								and bridges traditional psychotherapy with somatic approaches
								within a context of relational practice. NARM is a
								mindfulness-based clinical treatment, as its method is grounded
								in a phenomenological approach to addressing identity and
								consciousness of self – who we truly are beneath these patterned
								ways of relating to ourselves and the world. Seen in this way,
								healing Complex Trauma is a vehicle for transformation on a
								personal and collective level.
							</p>
						</div>
					</div>
					<div className="intro mb-5">
						<h2 className="text-white">
							The NeuroAffective Relational Model™ (NARM)
						</h2>
						<p className="text-white">
							In recent years the role of self-regulation has become an
							important part of psychological thinking.
						</p>
					</div>
					<div className="row p-3">
						<div className="col-md-11">
							<div className="row">
								<div className="col-md-10">
									<div className="testimonial-content mb-5">
										<p className="text-white">
											<strong className="text-white">
												The NeuroAffective Relational Model™ (NARM)
											</strong>{" "}
											brings the current understanding of self-regulation into
											clinical practice. This resource-oriented, non-regressive
											model emphasizes helping clients establish connection to
											the parts of self that are organized, coherent and
											functional. It helps bring into awareness and organization
											the parts of self that are disorganized and dysfunctional
											without making the regressed, dysfunctional elements the
											primary theme of the therapy.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>{" "}
		</section>{" "}
		<div class="page-content">
			<div class="container">
				<div className="intro mb-5">
					<h2 className="text-orange">Core Principles</h2>
					<h4 className="text-orange">
						The NeuroAffective Relational Model™ focuses on the fundamental
						tasks and functional unity of biological and psychological
						development. The NARM model:
					</h4>
				</div>
				<div className="row p-3">
					<div className="col-md-11">
						<div className="row">
							<div className="col-md-10">
								<ul className="checkmark">
									<li>
										<p className="text-primary">
											<strong className="text-primary">
												Integrates both a nervous system based and a relational
												orientation.
											</strong>{" "}
										</p>
									</li>
									<li>
										<p className="text-primary">
											<strong className="text-primary">
												Brings developmentally-informed clinical interventions
												that use somatic mindfulness and an orientation to
												resources to anchor self-regulation in the nervous
												system.
											</strong>{" "}
										</p>
									</li>
									<li>
										<p className="text-white">
											<strong className="text-primary">
												Works clinically with the link between psychological
												issues and the body by helping access the body’s
												self-regulatory capacities and by supporting nervous
												system re-regulation.
											</strong>{" "}
										</p>
									</li>
									<li>
										<p className="text-white">
											<strong className="text-primary">
												Uses mindful inquiry into the deeper identifications and
												counter-identifications that we take to be our identity.
											</strong>{" "}
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="intro mb-5">
					<h2 className="text-orange" />
					<h4 className="text-orange">
						In the NARM approach, we work simultaneously with the physiology and
						the psychology of individuals who have experienced developmental
						trauma, and focus on the interplay between issues of identity and
						the capacity for connection and regulation.
					</h4>
					<h4 className="text-orange">
						NARM uses four primary organizing principles:
					</h4>
				</div>
				<div className="row p-3">
					<div className="col-md-11">
						<div className="row">
							<div className="col-md-10">
								<ul className="checkmark">
									<li>
										<p className="text-primary">
											<strong className="text-primary">
												Supporting connection and organization
											</strong>{" "}
										</p>
									</li>
									<li>
										<p className="text-primary">
											<strong className="text-primary">
												Exploring identity
											</strong>{" "}
										</p>
									</li>
									<li>
										<p className="text-white">
											<strong className="text-primary">
												Working in present time
											</strong>{" "}
										</p>
									</li>
									<li>
										<p className="text-white">
											<strong className="text-primary">
												Regulating the nervous system
											</strong>{" "}
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<section id="services" className="component pt-0">
			<div className="inner-services-container">
				<div className="container px-5 px-md-3">
					<div className="container px-5 px-md-3" />
					<div className="intro mb-5">
						<h2 className="text-orange">
							Five Organizing Developmental Themes
						</h2>
						<h4 className="text-orange">
							There are five developmental life themes and associated core
							resources that are essential to our capacity for self-regulation
							and affect our ability to be present to self and others in the
							here-and-now:
						</h4>
					</div>
					<div className="row p-3">
						<div className="col-md-11">
							<div className="row">
								<div className="col-md-10">
									<div className="testimonial-content mb-5">
										<ul className="checkmark">
											<li>
												<p className="text-black">
													<strong className="text-orange">
														Connection.&nbsp;
													</strong>{" "}
													We feel that we belong in the world. We are in touch
													with our body and our emotions and capable of
													consistent connection with others.
												</p>
											</li>
											<li>
												<p className="text-black">
													<strong className="text-orange">
														Attunement.&nbsp;
													</strong>{" "}
													Our ability to know what we need and to recognize,
													reach out for, and take in the abundance that life
													offers.
												</p>
											</li>
											<li>
												<p className="text-black">
													<strong className="text-orange">Trust.&nbsp;</strong>
													We have an inherent trust in ourselves and others. We
													feel safe enough to allow a healthy interdependence
													with others.
												</p>
											</li>
											<li>
												<p className="text-black">
													<strong className="text-orange">
														Autonomy.&nbsp;
													</strong>
													We are able to say no and set limits with others. We
													speak our mind without guilt or fear.
												</p>
											</li>
											<li>
												<p className="text-black">
													<strong className="text-orange">
														Love-Sexuality.&nbsp;
													</strong>
													Our heart is open and we are able to integrate a
													loving relationship with a vital sexuality.
												</p>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="intro mb-5">
						<h2 className="text-orange" />
						<h4 className="text-orange">
							To the degree that these five basic needs are met, we experience
							regulation and connection. We feel safe and trusting of our
							environment, fluid and connected to ourselves and others. We
							experience a sense of regulation and expansion. To the degree that
							these basic needs are not met, we develop survival styles to try
							to manage the disconnection and dysregulation.
						</h4>
					</div>
				</div>
			</div>{" "}
		</section>{" "}
		<div class="page-content">
			<div class="container">
				<div className="intro mb-4 text-center ">
					<div className="lead-img mb-3">
						<LazyLoadImage
							effect="blur"
							src="/assets/img/chart-narm.png"
							alt="inner circle"
						/>
					</div>
				</div>

				<h2 className="text-orange" />
				<h4 className="text-orange">A Fundamental Shift</h4>
				<p className="text-black">
					<strong className="text-orange" />
					Whereas much of psychodynamic psychotherapy has been oriented toward
					identifying pathology and focusing on problems, NARM is a model for
					therapy and growth that emphasizes working with strengths as well as
					with symptoms. It orients towards resources, both internal and
					external, in order to support the development of an increased capacity
					for self regulation.
				</p>
				<p className="text-black">
					<strong className="text-orange" />
					At the heart of what may seem like a wide range of physical and
					emotional symptoms, most psychological and many of physiological
					problems can be traced to a disturbance in one or more of the five
					organizing developmental themes related to the survival styles.
				</p>
				<p className="text-black">
					<strong className="text-orange" />
					Initially, survival styles are adaptive, representing success, not
					pathology. However, because the brain uses the past to predict the
					future, these survival patterns remain fixed in our nervous system and
					create an adaptive but false identity. It is the persistence of
					survival styles appropriate to the past that distorts present
					experience and creates symptoms. These survival patterns, having
					outlived their usefulness, create ongoing disconnection from our
					authentic self and from others.
				</p>
				<p className="text-black">
					<strong className="text-orange" />
					In NARM the focus is less on why a person is the way they are and more
					on how their survival style distorts what they are experiencing in the
					present moment. Understanding how patterns began can be helpful to the
					client but is primarily useful to the degree that these patterns have
					become survival styles that influence present experience.
				</p>
				<h4 className="text-orange">The Metaprocess</h4>
				<p className="text-black">
					<strong className="text-orange" />
					Each therapeutic tradition has an implicit metaprocess. The
					metaprocess teaches clients to pay attention to certain elements of
					their experience and to ignore others. When therapies focus on
					deficiency, pain, and dysfunction, clients become skilled at orienting
					toward deficiency, pain, and dysfunction. Focusing on the difficulties
					of the past does not sufficiently reduce dysfunction nor support
					self-regulation.
				</p>
				<p className="text-black">
					<strong className="text-orange" />
					The metaprocess for the NARM model is the mindful awareness of self in
					the present moment. The client is invited into a fundamental process
					of inquiry:
				</p>
				<div className="intro mb-5">
					<h4 className="text-orange p-5">
						<i>
							“What are the patterns that are preventing me from being present
							to myself and others at this moment and in my life?”
						</i>
					</h4>
				</div>
				<p className="text-black">
					<strong className="text-orange" />
					We explore this question on the following levels of experience:
					cognitive, emotional, felt sense, and physiological. NARM explores
					personal history to the degree that patterns from the past interfere
					with being present and in contact with self and others in the
					here-and-now. It brings an active process of inquiry to clients’
					relational and survival styles, building on their strengths and
					helping them to experience a sense of agency in the difficulties of
					their current life.
				</p>
				<div className="intro">
					<h4 className="text-orange">
						The NARM metaprocess involves two aspects of mindfulness:
					</h4>
					<div className="row p-3">
						<div className="col-md-10">
							<div className="testimonial-content">
								<ul className="checkmark">
									<li>
										<p className="text-black">
											<strong className="text-orange">
												Somatic mindfulness
											</strong>
										</p>
									</li>
									<li>
										<p className="text-black">
											<strong className="text-orange">
												Mindful awareness of the organizing principles of one’s
												adaptive survival styles
											</strong>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<p className="text-black">
					<strong className="text-orange" />
					Using a dual awareness that is anchored in the present moment, a
					person becomes mindful of cognitive, emotional, and physiological
					patterns that began in the past while not falling into the trap of
					making the past more important than the present. Working with the NARM
					approach progressively reinforces the connection to self in the
					present moment. Tracking the process of connection/disconnection,
					regulation/dysregulation in present time helps clients connect with
					their sense of agency and feel less like victims of their childhood.
				</p>
				<p className="text-black">
					<strong className="text-orange" />
					Using resource-oriented techniques that work with subtle shifts in the
					nervous system adds significant effectiveness. Working with the
					nervous system is fundamental in disrupting the predictive tendencies
					of the brain. It is connection to our body and to other people that
					brings healing re-regulation. Using techniques that support increased
					connection with self and others is instrumental in supporting
					effective re-regulation.
				</p>
				<h4 className="text-orange">Bottom-Up and Top-Down</h4>
				<p className="text-black">
					<strong className="text-orange" />
					There are continual loops of information going in both directions from
					the body to the brain and from the brain to the body. There are
					similar loops within the lower and higher structures of the brain,
					that is between the brain stem, limbic system, and cortex.
				</p>
				<p className="text-black">
					<strong className="text-orange" />
					NARM uses both top-down and bottom-up approaches. Top-down approaches
					emphasize cognitions and emotions as the primary focus. Bottom-up
					approaches, on the other hand, focus on the body, the felt sense and
					the instinctive responses as they are mediated through the brain stem
					toward higher levels of brain organization. Using both bottom-up and
					top-down orientations greatly expands therapeutic options.
				</p>
				<h4 className="text-orange">Working with the Life Force</h4>
				<p className="text-black">
					<strong className="text-orange" />
					The spontaneous movement in all of us is toward connection and health.
					No matter how withdrawn and isolating we have become, or how serious
					the trauma we have experienced, on the deepest level, just as a plant
					spontaneously moves towards the sun, there is in each of us an impulse
					moving toward connection. This organismic impulse is the fuel of The
					NeuroAffective Relational Model™.
				</p>
			</div>
		</div>
	</Layout>
)

export default WhatisNarm
